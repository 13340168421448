<template>
    <div>
        <base-header base-title="Report Daftar Perujuk"></base-header>
        <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
            <div class="kt-portlet kt-portlet--mobile">
                <div class="kt-portlet__head kt-portlet__head--lg">
                    <base-title :head-title="headTitle"></base-title>
                </div>
                <div class="kt-portlet__body">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-8">
                                    <div class="form-group row">
                                        <label class="col-md-4 col-form-label">{{ $t('common.tanggalDaftar') }} : </label>
                                        <div class="col-md-8 pt-1">
                                            <div class="input-group input-group-sm date">
                                                <div class="input-group-prepend">
                                                <span class="input-group-text">
                                                    <i class="la la-calendar-o glyphicon-th"></i>
                                                </span>
                                                </div>
                                                <date-range-picker
                                                :changedValueStart.sync="dateStart"
                                                :changedValueEnd.sync="dateEnd"
                                                id-date-picker="filter_rangedatepicker"
                                                picker-value
                                                place-holder="DD/MM/YYYY - DD/MM/YYYY"
                                                ></date-range-picker>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group row">
                                        <button
                                            type="button"
                                            @click="submitSearch()"
                                            class="btn btn-sm btn-default btn-bold btn-upper mt-1 ml-2"
                                        >
                                            <i class="flaticon2-search"></i> {{ $t('button.search') }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div id="btn_export_container">
                            </div>
                        </div>
                    </div>
                    <div v-if="showLoading" class="m-auto p-5 d-flex flex-row h-100">
                        <div class="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--primary my-auto"></div>
                        <h5 class="my-auto pl-5">Loading ...</h5>
                    </div>
                    <div v-if="isLoaded">
                        <data-tables :table="table"></data-tables>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CheckPermission from "./../../../services/checkPermission.service";
const checkPermission = new CheckPermission();
import BaseTitle from "./../../_base/BaseTitle";
import BaseHeader from "./../../_base/BaseHeader";
import DateRangePicker from "./../../_general/DateRangePicker";
import DaftarPerujuk from "./../../../constants/report-daftar-perujuk";
import DataTables from "./../../_table/DataTables";
import { RepositoryFactory } from "./../../../repositories/RepositoryFactory";
const ReportRepository = RepositoryFactory.get("report");
export default {
    components: {
        BaseHeader,
        BaseTitle,
        DateRangePicker,
        DataTables
    },
    data() {
        var vx = this;
        return {
            headTitle: "Report Daftar Perujuk",
            dateStart: null,
            dateEnd: null,
            showLoading:false,
            isLoaded:false,
            tableName: "table_daftar_perujuk",
            table: {
                dateFormat: "dd-M-yyyy",
                tableName: "table_daftar_perujuk",
                tableDataResult: [],
                tableColumnDefs: [
                    {
                        defaultContent: " ",
                        targets: "_all"
                    },
                    {
                        targets: 11,
                        visible: false
                    },
                    {
                        targets: 3,
                        render: function ( data, type, row, meta ) {
                            return (data) ? vx.formatDate(data) : '';
                        }
                    },
                ],
                buttons:[
                    {
                        extend: 'excel',
                        title: 'Laporan Daftar Perujuk',
                        messageTop:'',
                        exportOptions: {
                            // columns: ':visible'
                            columns: [0,1,2,3,4,5,6,7,8,9,10]
                        },
                    },
                    {
                        extend: 'pdfHtml5',
                        orientation: 'landscape',
                        pageSize: 'A4',
                        title:'Laporan Daftar Perujuk',
                        exportOptions: {
                            columns: [0,1,2,3,4,5,6,7,8,9,10]
                        },
                        customize( doc ) {
                            doc.content.splice( 1, 0,
                                {
                                    text: [
                                        {text : "Periode     : ", bold: true},
                                        {text : vx.formatDate(vx.dateStart) + " - " + vx.formatDate(vx.dateEnd) +"\n \n"}
                                    ],
                                    alignment: 'left'
                                }
                            );
                        }
                    },
                    {
                        extend: 'print',
                        exportOptions: {
                            columns: [0,1,2,3,4,5,6,7,8,9,10]
                        },
                        title: 'Laporan Daftar Perujuk ',
                        header: true,
                        footer: true,
                        text: 'Print Laporan',
                        titleAttr: 'Print',
                        customize: win => {
                            $(win.document.body).css('font-size', '12pt');
                            $(win.document.body)
                                .find('table')
                                .addClass('compact')
                                .css('font-size', 'inherit');
                            $(win.document.body)
                                .find('h1')
                                .after(
                                    `<div class="row mt-5 mb-3 mx-0 px-0">
                                        <div class="col-2 text-left">
                                            <div class="row"><h5>Periode</h5></div>
                                        </div>
                                        <div class="col-5 text-left">
                                            <div class="row"><h5>: ` + vx.formatDate(vx.dateStart) + ` - ` + vx.formatDate(vx.dateEnd) + `
                                        </div>
                                     </div>
                                    `
                                );
                            $(win.document.body)
                                .find('h1')
                                .addClass('text-center mt-3 mb-5');
                            const css = '@page { size: landscape; }',
                                head = win.document.head || win.document.getElementsByTagName('head')[0],
                                style = win.document.createElement('style');
                            style.type = 'text/css';
                            style.media = 'print';
                            if (style.styleSheet) {
                                style.styleSheet.cssText = css;
                            } else {
                                style.appendChild(win.document.createTextNode(css));
                            }
                            head.appendChild(style);
                        }
                    }
                ],
                ordering:false,
                tableColumns: [
                    {
                        title: "No",
                        data: "no"
                    },
                    {
                        title: "Kode Perujuk",
                        data: "kodePerujuk"
                    },
                    {
                        title: "Nama Perujuk",
                        data: "namaPerujuk"
                    },
                    {
                        title: "Tanggal Daftar",
                        data: "tanggalDaftar"
                    },
                    {
                        title: "No",
                        data: "noAlamat"
                    },
                    {
                        title: "Alamat Perujuk",
                        data: "alamat"
                    },
                    {
                        title: "Rs / Faskes Perujuk",
                        data: "faskes"
                    },
                    {
                        title: "No Telp 1",
                        data: "noTelp1"
                    },
                    {
                        title: "No Telp 2",
                        data: "noTelp2"
                    },
                    {
                        title: "Email",
                        data: "email"
                    },
                    {
                        title: "Keterangan",
                        data: "keterangan"
                    },
                    {
                        title:"Actions",
                        data:"kodePerujuk"
                    }
                ],
                tableFilter: [
                    {
                        title: "Nama Perujuk",
                        type: "text"
                    },
                    {
                        title: "Tanggal Daftar",
                        type: "datepicker"
                    },
                    {
                        title: "Alamat Perujuk",
                        type: "text"
                    },
                    {
                        title: "Rs / Faskes Perujuk",
                        type: "text"
                    },
                    {
                        title: "No Telp 1",
                        type: "text"
                    },
                    {
                        title: "No Telp 2",
                        type: "text"
                    },
                    {
                        title: "Email",
                        type: "text"
                    },
                    {
                        title: "Keterangan",
                        type: "text"
                    },
                    {
                        title: "Actions",
                        type: "actions"
                    }
                ]
            }
        }
    },
    watch: {

    },
    methods: {
        formatDate: function (data) {
            var momentDate = moment(data);
            return(momentDate.format("DD-MMM-YYYY"));
        },
        submitSearch: function () {
            this.showLoading = true;
            this.isLoaded = false;
            this.table.buttons[0].messageTop = 'Periode : ' + this.formatDate(this.dateStart) + ' - ' + this.formatDate(this.dateEnd);
            this.fetch();
        },
        async fetch(resolve) {

                var params = {
                    sort: ['kategoriNama,ASC', 'id,ASC'],
                    createdDateStart: this.dateStart,
                    createdDateEnd: this.dateEnd
                };
                new Promise((resolve, reject) => {
                    ReportRepository.reportPerujuk(params, resolve, reject );
                })
                .then(response => {
                    const data = this.constructData(response.data);
                    if (this.isLoaded) {
                        var datatable = $("#" + this.tableName).DataTable();
                        datatable.clear();
                        datatable.rows.add(data);
                        datatable.draw("full-hold");
                        if(resolve !== undefined) {
                            resolve(data);
                        }
                    } else {
                        this.table.tableDataResult = data;
                        this.showLoading = false;
                        this.isLoaded = true;
                    }
                }).catch(error => {
                    var msg = (error.message) ? error.message : error;
                    this.showToastError('Daftar Perujuk : ' + msg);
                })

        },
        constructData: function (data) {
            let sortedData = [];
            let count = 0;
            let beforeCatId = 0;
            data.forEach((perujuk,i) => {
                const param1 = moment.utc(perujuk.createdDateTime).isSameOrAfter(moment.utc(this.dateStart).subtract(7,'hours'));
                const param2 =  moment.utc(perujuk.createdDateTime).isSameOrAfter(moment.utc(this.dateEnd).subtract(7,'hours'));
                if(param1 && param1){
                    const firstNama = perujuk.firstName ? perujuk.firstName : '';
                    const lastNama = perujuk.lastName ? perujuk.lastName : '';
                    const nama = firstNama + ' ' + lastNama;
                    if(beforeCatId != perujuk.kategoriId) {
                        count = 0;
                        beforeCatId = perujuk.kategoriId;
                        let grouphead = new DaftarPerujuk();
                        grouphead.no = '<b>'+perujuk.kategoriNama+'</b>';
                        sortedData.push(grouphead);
                    }
                    count = count + 1;
                    let temp = new DaftarPerujuk();
                    temp.no = count;
                    temp.kodePerujuk = perujuk.id;
                    temp.namaPerujuk = nama;
                    temp.tanggalDaftar = perujuk.createdDateTime;
                    temp.noAlamat = 1;
                    temp.alamat = (perujuk.alamatPerujuks.length > 0) ? perujuk.alamatPerujuks[0].jalan : null;
                    temp.faskes = (perujuk.alamatPerujuks.length > 0) ? perujuk.alamatPerujuks[0].nama : null;
                    temp.email = perujuk.email;
                    temp.noTelp1 = (perujuk.teleponPerujuks.length > 0) ? perujuk.teleponPerujuks[0].nomor : null;
                    temp.noTelp2 = (perujuk.teleponPerujuks.length > 1) ? perujuk.teleponPerujuks[1].nomor : null;
                    sortedData.push(temp);
                    if ( perujuk.alamatPerujuks.length > 0 ) {
                        perujuk.alamatPerujuks.forEach((alamats , j) => {
                            if(j > 0){
                                temp = new DaftarPerujuk();
                                temp.noAlamat = j + 1;
                                temp.alamat = alamats.jalan;
                                temp.faskes = alamats.nama;
                                sortedData.push(temp);
                            }
                        });
                    }
                }
            });
            return sortedData;
        },
        showToastError: function(message) {
            toastr.error(message);
        }
    },
    created() {

    }
}
</script>
