export default function DaftarPerujuk() {
    return {
        id: null,
        tanggal: null,
        noUrut: null,
        kategori: null,
        kodePerujuk: null,
        namaPerujuk: null,
        namaFaskes: null,
        noTelp: null,
        noUrutPasien: null,
        namaPasien: null,
        medicalRecordNo: null,
        tanggalMasuk: null,
        tanggalKeluar: null,
        jenisTindakan: null,
        kelasPerawatan: null,
        jenisPenjamin: null,
        namaPenjamin: null,
        totalTagihan: null,
        jumlah: null,
        tglPenyerahan: null
    };
}
