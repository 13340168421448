<template>
    <div>
        <base-header base-title="Report Status Referral Fee Keuangan"></base-header>
        <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
            <div class="kt-portlet kt-portlet--mobile">
                <div class="kt-portlet__head kt-portlet__head--lg">
                    <base-title :head-title="headTitle"></base-title>
                </div>
                <div class="kt-portlet__body">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label class="col-md-4 col-form-label">{{ $t('common.kategori') }} :</label>
                                        <div class="col-md-8">
                                            <kategori-select
                                                v-model="kategori"
                                                :placeholder="'All'"
                                            ></kategori-select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <div class="col-md-4 col-form-label">{{ $t('common.status') }} :</div>
                                        <div class="col-md-8">
                                            <status-fee-select
                                                :changedValue.sync="status"
                                                :id-select-picker="'statusFee'"
                                                :selected-picker="status"
                                                :is-validate="true"
                                            ></status-fee-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-8">
                                    <div class="form-group row">
                                        <label class="col-md-3 col-form-label">{{ $t('common.periode') }}:</label>
                                        <div class="col-md-9 pt-1">
                                            <div class="input-group input-group-sm date">
                                                <div class="input-group-prepend">
                                                <span class="input-group-text">
                                                    <i class="la la-calendar-o glyphicon-th"></i>
                                                </span>
                                                </div>
                                                <date-range-picker
                                                :changedValueStart.sync="dateStart"
                                                :changedValueEnd.sync="dateEnd"
                                                id-date-picker="filter_rangedatepicker"
                                                picker-value
                                                place-holder="DD/MM/YYYY - DD/MM/YYYY"
                                                ></date-range-picker>
                                            </div>
                                            <div
                                                v-if="status"
                                                class="keterangan"
                                                style="fontSize:80%; marginTop:0.25rem; width:100%; fontWeight:400;"
                                            >Periode adalah rentang waktu saat terjadi <b>perubahan status</b> menjadi :
                                                <ul>
                                                    <li v-if="status == statusFeeEnum.BELUM_DIBAYAR || status == statusFeeEnum.ALL">Dalam Proses Pembayaran<span v-if="status == statusFeeEnum.ALL">, atau</span></li>
                                                    <li v-if="status == statusFeeEnum.SUDAH_DIBAYAR || status == statusFeeEnum.ALL">Sudah Terbayarkan, atau</li>
                                                    <li v-if="status == statusFeeEnum.SUDAH_DIBAYAR || status == statusFeeEnum.ALL">Closed</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group row">
                                        <button
                                            type="button"
                                            @click="submitSearch()"
                                            class="btn btn-sm btn-default btn-bold btn-upper mt-1 ml-2 "
                                            >
                                            <i class="flaticon2-search"></i> {{ $t('button.search') }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                                <div id="btn_export_container">
                                </div>
                        </div>


                    </div>
                    <div v-if="showLoading" class="m-auto p-5 d-flex flex-row h-100">
                        <div class="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--primary my-auto"></div>
                        <h5 class="my-auto pl-5">Loading ...</h5>
                    </div>
                    <div v-if="isLoaded">
                        <data-tables :table="table"></data-tables>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CheckPermission from "./../../../services/checkPermission.service";
const checkPermission = new CheckPermission();
import BaseTitle from "./../../_base/BaseTitle";
import BaseHeader from "./../../_base/BaseHeader";
import DateRangePicker from "./../../_general/DateRangePicker";
import DaftarPerujuk from "./../../../constants/report-daftar-perujuk";
import DataTables from "./../../_table/DataTables";
import { RepositoryFactory } from "./../../../repositories/RepositoryFactory";
import KategoriSelect from "./../../_select/Kategori.vue";
import StatusFeeSelect from "./../../_select/StatusFeeKeuangan";
import JejakEnum from "./../../../constants/jejak-enum";
import StatusFeeEnum from "./../../../constants/status-fee-keuangan";
import StatusFee from "./../../../constants/report-status-fee";
import TeleponEnum from "./../../../constants/telepon-enum";
const ReportRepository = RepositoryFactory.get("report");
export default {
    components: {
        BaseHeader,
        BaseTitle,
        DateRangePicker,
        DataTables,
        KategoriSelect,
        StatusFeeSelect
    },
    data() {
        var vx = this;
        return {
            headTitle: "Report Status Referral Fee Keuangan",
            dateStart: null,
            dateEnd: null,
            kategori:null,
            status:null,
            showLoading:false,
            isLoaded:false,
            jejakEnum: JejakEnum,
            statusFeeEnum: StatusFeeEnum,
            teleponEnum: TeleponEnum,
            tableName: "table_status_rujukan",
            mainTitle: "Laporan Status Referral Fee Keuangan",
            subTitle: "",
            table: {
                tableName: "table_status_rujukan",
                dateFormat: "dd-M-yyyy",
                tableDataResult: [],
                tableColumnDefs: [
                    {
                        defaultContent: " ",
                        targets: "_all"
                    },
                    {
                        targets: 19,
                        visible: false
                    },
                    {
                        targets:[15, 16],
                        className: 'text-right',
                    }
                ],
                buttons:[
                    {
                        extend: 'excel',
                        title: "Laporan Status Referral Fee Keuangan",
                        messageTop: vx.subTitle,
                        exportOptions: {
                            columns: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18],
                            format: {
                            body(data, row, column, node) {
                                    return [15, 16].includes(column) ? data.replace(/\./g, '').replace(',', '.') : data;
                                }
                            }
                        },
                    },
                    {
                        extend: 'pdfHtml5',
                        orientation: 'landscape',
                        pageSize: 'A4',
                        title: "Laporan Status Referral Fee Keuangan",
                        exportOptions: {
                            columns: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18]
                        },
                        customize(doc) {
                            doc.defaultStyle.fontSize = 5;
                            doc.styles.tableHeader.fontSize = 5;
                            doc.content.splice( 1, 0,
                                {
                                    text: [
                                        {text : "Kategori : ", bold: true},
                                        {text : (vx.kategori !== null) ? vx.kategori.nama : 'Semua Kategori'},
                                        {text : "\n"},
                                        {text : "Status : ", bold: true},
                                        {text : (vx.status !== vx.statusFeeEnum.ALL) ? vx.status : 'SUDAH DIBAYAR DAN BELUM DIBAYAR'},
                                        {text : "\n"},
                                        {text : "Periode     : ", bold: true},
                                        {text : vx.formatDate(vx.dateStart) + " - " + vx.formatDate(vx.dateEnd) +"\n \n"}
                                    ],
                                    alignment: 'left'
                                }
                            );
                        }
                    },
                    {
                        extend: 'print',
                        exportOptions: {
                            columns: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18]
                        },
                        title: 'Laporan Status Referral Fee Keuangan ',
                        header: true,
                        footer: true,
                        text: 'Print Laporan',
                        titleAttr: 'Print',
                        customize: win => {
                            $(win.document.body).css('font-size', '5pt');
                            $(win.document.body)
                                .find('table')
                                .addClass('compact')
                                .css('font-size', 'inherit');
                            $(win.document.body)
                                .find('h1')
                                .after(
                                    `<div class="row  mt-5 mb-3 mx-0 px-0">
                                        <div class="col-2 text-left">
                                            <div class="row"><h5>Kategori :</h5></div>
                                            <div class="row"><h5>Status : </h5></div>
                                            <div class="row"><h5>Periode : </h5></div>
                                        </div>
                                        <div class="col-5 text-left">
                                            <div class="row"><h5>: ${(vx.kategori !== null) ? vx.kategori.nama : 'Semua Kategori'}</h5></div>
                                            <div class="row"><h5>: ${(vx.status !== vx.statusFeeEnum.ALL) ? vx.status : 'SUDAH DIBAYAR DAN BELUM DIBAYAR'}</h5></div>
                                            <div class="row"><h5>: ` + vx.formatDate(vx.dateStart) + ` - ` + vx.formatDate(vx.dateEnd) + `
                                        </div>
                                     </div>
                                    `
                                );
                            $(win.document.body)
                                .find('h1')
                                .addClass('text-center mt-3 mb-5');
                            const css = '@page { size: landscape; }',
                                head = win.document.head || win.document.getElementsByTagName('head')[0],
                                style = win.document.createElement('style');
                            style.type = 'text/css';
                            style.media = 'print';
                            if (style.styleSheet) {
                                style.styleSheet.cssText = css;
                            } else {
                                style.appendChild(win.document.createTextNode(css));
                            }
                            head.appendChild(style);
                        }
                    }
                ],
                ordering:false,
                tableColumns: [
                    {
                        title: 'No Urut',
                        data: 'noUrut',
                    },
                    {
                        title: 'Kategori',
                        data: 'kategori',
                    },
                    {
                        title: 'Kode Perujuk',
                        data: 'kodePerujuk',
                    },
                    {
                        title: 'Nama Perujuk',
                        data: 'namaPerujuk',
                    },
                    {
                        title: 'Nama Faskes',
                        data: 'namaFaskes',
                    },
                    {
                        title: 'No Telp',
                        data: 'noTelp',
                    },
                    {
                        title: 'No.',
                        data: 'noUrutPasien',
                    },
                    {
                        title: 'Nama Pasien',
                        data: 'namaPasien',
                    },
                    {
                        title: 'Medical Record',
                        data: 'medicalRecordNo',
                    },
                    {
                        title: 'Nomor Registrasi',
                        data: 'nomorRegistrasi',
                    },
                    {
                        title: 'Tanggal Masuk Dirawat',
                        data: 'tanggalMasuk',
                    },
                    {
                        title: 'Tanggal Keluar Dirawat',
                        data: 'tanggalKeluar',
                    },
                    {
                        title: 'Jenis Tindakan',
                        data: 'jenisTindakan',
                    },
                    {
                        title: 'Kelas Perawatan',
                        data: 'kelasPerawatan',
                    },
                    {
                        title: 'Jenis Penjamin',
                        data: 'jenisPenjamin',
                    },
                    {
                        title: 'Total Tagihan',
                        data: 'totalTagihan',
                    },
                    {
                        title: 'Biaya Kesehatan',
                        data: 'jumlah',
                    },
                    {
                        title: 'Tgl Proses Pembayaran',
                        data: 'tanggal',
                    },
                    {
                        title: 'Tgl Penyerahan Referral',
                        data: 'tglPenyerahan',
                    },
                    {
                        title:"Actions",
                        data:"id"
                    }
                ],
                tableFilter: [
                    {
                        title: 'Kategori',
                        type: 'text',
                    },
                    {
                        title: 'Kode Perujuk',
                        type: 'text',
                    },
                    {
                        title: 'Nama Perujuk',
                        type: 'text',
                    },
                    {
                        title: 'Nama Fasker',
                        type: 'text',
                    },
                    {
                        title: 'No Telp',
                        type: 'text',
                    },
                    {
                        title: 'Nama Pasien',
                        type: 'text',
                    },
                    {
                        title: 'Medical Record',
                        type: 'text',
                    },
                    {
                        title: 'Nomor Registrasi',
                        type: 'text',
                    },
                    {
                        title: 'Tanggal Masuk Dirawat',
                        type: 'datepicker',
                    },
                    {
                        title: 'Tanggal Keluar Dirawat',
                        type: 'datepicker',
                    },
                    {
                        title: 'Jenis Tindakan',
                        type: 'text',
                    },
                    {
                        title: 'Kelas Perawatan',
                        type: 'text',
                    },
                    {
                        title: 'Jenis Penjamin',
                        type: 'text',
                    },
                    {
                        title: 'Total Tagihan',
                        type: 'text',
                    },
                    {
                        title: 'Biaya Kesehatan',
                        type: 'text',
                    },
                    {
                        title: 'Tgl Proses Pembayaran',
                        type: 'datepicker',
                    },
                    {
                        title: 'Tgl Penyerahan Referral',
                        type: 'datepicker',
                    },
                    {
                        title: "Actions",
                        type: "actions"
                    }
                ]
            }
        }
    },
    provide() {
        return {
            $validator: this.$validator
        };
    },
    methods: {
        convertSeperator: function(angka) {
            if (angka !== null) {
                const parts = angka.toString().split('.');
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                return parts.join(',');
            }
            return angka;
        },
        findLastIndex: function(array, searchKey, searchValue) {
            const index = array
                .slice()
                .reverse()
                .findIndex(x => x[searchKey] === searchValue);
            const count = array.length - 1;
            const finalIndex = index >= 0 ? count - index : index;
            return finalIndex;
        },
        findTimeStamp: function(array, searchKey, searchValue) {
            const sortArray = array.sort((a,b) => moment(b.timestamp) - moment(a.timestamp));
            const index = sortArray.findIndex(x => x[searchKey] === searchValue);
            if (index >= 0) {
                return sortArray[index];
            } else {
                return null;
            }
        },
        formatDate: function (data , format = "DD-MMM-YYYY") {
            var momentDate = moment(data);
            return(momentDate.format(format));
        },
        submitSearch: function () {
            var vx = this;
            this.$validator.validateAll().then(result => {
                if (result) {
                    vx.showLoading = true;
                    vx.isLoaded = false;
                    vx.subTitle = '';
                    vx.subTitle = (vx.kategori !== null) ? vx.subTitle + ' Kategori ' + vx.kategori.nama + ' , ' : 'Semua Kategori , ';
                    vx.subTitle = (vx.status !== vx.statusFeeEnum.ALL) ? vx.subTitle + 'Status Fee ' + vx.status + ' , ' : vx.subTitle + 'Status Fee SUDAH DIBAYAR dan BELUM DIBAYAR , ';
                    vx.subTitle = vx.subTitle +'Periode ' +vx.formatDate(vx.dateStart) +' - ' +vx.formatDate(vx.dateEnd);
                    vx.table.buttons[0].messageTop = vx.subTitle;

                    vx.fetch();
                }
            })
        },
        async fetch(resolve) {
                let jejaks = [];
                if (this.status === this.statusFeeEnum.ALL) {
                    jejaks = [this.jejakEnum.DALAM_PROSES_PEMBAYARAN, this.jejakEnum.SUDAH_TERBAYARKAN];
                } else if (this.status === this.statusFeeEnum.SUDAH_DIBAYAR) {
                    jejaks = [this.jejakEnum.SUDAH_TERBAYARKAN];
                } else {
                    jejaks = [this.jejakEnum.DALAM_PROSES_PEMBAYARAN];
                }
                let stat = [];
                if (this.status === this.statusFeeEnum.ALL) {
                    stat = [this.jejakEnum.DALAM_PROSES_PEMBAYARAN, this.jejakEnum.SUDAH_TERBAYARKAN, this.jejakEnum.CLOSED];
                } else if (this.status === this.statusFeeEnum.SUDAH_DIBAYAR) {
                    stat = [this.jejakEnum.SUDAH_TERBAYARKAN, this.jejakEnum.CLOSED];
                } else {
                    stat = [this.jejakEnum.DALAM_PROSES_PEMBAYARAN];
                }
                var params = {
                    jejaksStartDate: this.dateStart,
                    jejaksEndDate: this.dateEnd,
                    jejaksEnumStages: jejaks,
                    jejakTerakhirStages: stat,
                    sort: ['perujuk.id,asc']
                };
                if(this.kategori != null) {
                    params['perujukKategoriId'] = this.kategori.id
                }
                new Promise((resolve, reject) => {
                    ReportRepository.reportReferral(params, resolve, reject );
                })
                .then(response => {
                    const data = this.constructData(response.data);
                    if (this.isLoaded) {
                        var datatable = $("#" + this.tableName).DataTable();
                        datatable.clear();
                        datatable.rows.add(data);
                        datatable.draw("full-hold");
                        if(resolve !== undefined) {
                            resolve(data);
                        }
                    } else {
                        this.table.tableDataResult = data;
                        this.showLoading = false;
                        this.isLoaded = true;
                    }
                }).catch(error => {
                    var msg = (error.message) ? error.message : error;
                    this.showToastError('Status Fee Keuangan : ' + msg);
                })

        },
        constructData: function (transaksiReferral) {
            let temp = [];
            let NoUrut = 1;
            transaksiReferral.forEach(data => {
                let statusFee = new StatusFee();
                // nama
                const firstNama = (data.perujuk && data.perujuk.firstName) ? data.perujuk.firstName : null;
                const lastNama = (data.perujuk && data.perujuk.lastName) ? data.perujuk.lastName : null;
                const nama  = firstNama + ' ' + lastNama;
                // medicalRecordNo
                const medicalRecordNo = data.medicalRecordNo ? data.medicalRecordNo : null;
                const nomorRegistrasi = data.nomorRegistrasi ? data.nomorRegistrasi : null;
                // Tanggal
                const tanggalMasuk = this.findTimeStamp(data.jejaks, 'enumStage', this.jejakEnum.PASIEN_DATANG);
                const tanggalKeluar = this.findTimeStamp(data.jejaks, 'enumStage', this.jejakEnum.PASIEN_PULANG);
                const tanggalDalamPembayaran = this.findTimeStamp(data.jejaks, 'enumStage', this.jejakEnum.DALAM_PROSES_PEMBAYARAN);
                const tanggalPenyerahan = this.findTimeStamp(data.jejaks, 'enumStage', this.jejakEnum.SUDAH_TERBAYARKAN);
                //param
                let param = (data.jejakTerakhirStage == this.jejakEnum.DALAM_PROSES_PEMBAYARAN) ?  this.jejakEnum.DALAM_PROSES_PEMBAYARAN : this.jejakEnum.SUDAH_TERBAYARKAN;
                const dateParam = this.findTimeStamp(data.jejaks, 'enumStage', param);
                const tanggal = moment(dateParam.timestamp);
                const tanggalAkhirRujuk = moment(this.tanggalAkhir);
                if (tanggalAkhirRujuk.isSameOrAfter(tanggal)) {
                    if(data.perujuk && !temp.find(x => x.id == data.perujuk.id)){
                        const NoTelp = data.perujuk.teleponPerujuks.find(y => y.tipe === this.teleponEnum.HP_PRIMARY);
                        statusFee.id = data.perujuk.id;
                        statusFee.tanggal = (tanggalDalamPembayaran) ? this.formatDate(tanggalDalamPembayaran.timestamp) : null;
                        statusFee.noUrut = NoUrut;
                        statusFee.kategori = data.perujuk.kategoriNama;
                        statusFee.kodePerujuk = data.perujuk.id;
                        statusFee.namaPerujuk = nama;
                        statusFee.namaFaskes = (data.perujuk.alamatPerujuks) ? data.perujuk.alamatPerujuks.map(s => s.nama).join(' - ') : null;
                        statusFee.noTelp = (NoTelp) ? NoTelp.nomor : null;
                        statusFee.noUrutPasien = 1;
                        statusFee.namaPasien = data.nama;
                        statusFee.medicalRecordNo = medicalRecordNo;
                        statusFee.nomorRegistrasi = nomorRegistrasi;
                        statusFee.tanggalMasuk = (tanggalMasuk) ? this.formatDate(tanggalMasuk.timestamp) : null;
                        statusFee.tanggalKeluar = (tanggalKeluar) ? this.formatDate(tanggalKeluar.timestamp) : null;
                        statusFee.jenisTindakan = (data.tindakan) ? data.tindakan.nama : null;
                        statusFee.kelasPerawatan = (data.kelas) ? data.kelas.nama : null;
                        statusFee.jenisPenjamin = (data.jenisPenjamin) ? data.jenisPenjamin.nama : null;
                        statusFee.namaPenjamin = null;
                        statusFee.totalTagihan = this.convertSeperator(data.totalBiaya);
                        statusFee.jumlah = this.convertSeperator(data.fee);
                        statusFee.tglPenyerahan = (param == this.jejakEnum.SUDAH_TERBAYARKAN) ? this.formatDate(tanggalPenyerahan.timestamp) : null;
                        temp.push(statusFee);
                        NoUrut++;

                    } else {
                        const index = this.findLastIndex(temp, 'id', data.perujuk.id);
                        statusFee.id = data.perujuk.id;
                        statusFee.tanggal = (tanggalDalamPembayaran) ? this.formatDate(tanggalDalamPembayaran.timestamp) : null;
                        statusFee.noUrutPasien = temp[index].noUrutPasien + 1;
                        statusFee.namaPasien = data.nama;
                        statusFee.medicalRecordNo = medicalRecordNo;
                        statusFee.nomorRegistrasi = nomorRegistrasi;
                        statusFee.tanggalMasuk = (tanggalMasuk) ? this.formatDate(tanggalMasuk.timestamp) : null;
                        statusFee.tanggalKeluar = (tanggalKeluar) ? this.formatDate(tanggalKeluar.timestamp) : null;
                        statusFee.jenisTindakan = (data.tindakan) ? data.tindakan.nama : null;
                        statusFee.kelasPerawatan = (data.kelas) ? data.kelas.nama : null;
                        statusFee.jenisPenjamin = (data.jenisPenjamin) ? data.jenisPenjamin.nama : null;
                        statusFee.namaPenjamin = null;
                        statusFee.totalTagihan = this.convertSeperator(data.totalBiaya);
                        statusFee.jumlah = this.convertSeperator(data.fee);
                        statusFee.tglPenyerahan = (param == this.jejakEnum.SUDAH_TERBAYARKAN) ? this.formatDate(tanggalPenyerahan.timestamp) : null;
                        temp.push(statusFee);
                    }
                }
            });
            return temp;
        },
        showToastError: function(message) {
            toastr.error(message);
        }
    },
    created() {

    }
}
</script>
