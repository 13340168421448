<template>
  <div class="kt-spinner kt-spinner--sm kt-spinner--brand kt-spinner--right kt-spinner--input">
    <div
      class="dropdown bootstrap-select show-tick form-control"
      :class="{'is-invalid': errors.has('selectedStatusFee') }"
    >
      <select
        @change="changedSelectPicker"
        class="form-control"
        id="status_fee_keuangan"
        v-model="selected"
        :value="selectedValue"
        data-size="5"
        :disabled="disabledSelect"
        v-validate="isValidate ? 'required' : ''"
        name="selectedStatusFee"
        data-vv-as="Status Rujukan"
        :class="{'is-invalid': errors.has('selectedStatusFee') }"
      >
        <option v-for="value in statusFee" :value="value" :key="value">{{ $t('EnumStage.'+value) }}</option>
      </select>
    </div>
    <div
      v-show="errors.first('selectedStatusFee')"
      class="invalid-feedback"
    >{{ errors.first('selectedStatusFee') }}</div>
  </div>
</template>

<script>

import statusFee from '../../constants/status-fee-keuangan';

export default {
  inject: ["$validator"],
  data() {
    return {
      dataResponse: null,
      selected: null,
      disabledSelect: true,
      statusFee: statusFee
    };
  },
  props: {
    selectedPicker: {},
    isValidate: { type: Boolean }
  },
  computed: {
    selectedValue() {
      this.selected = this.selectedPicker;
    }
  },
  methods: {
    getSelectPicker: function() {
      this.disabledSelect = false;
      $("#status_fee_keuangan").selectpicker();
      this.$nextTick(() => {
        $("#status_fee_keuangan").selectpicker("refresh");
      });
    },
    changedSelectPicker: function() {
      this.$emit("update:changedValue", this.selected);
    }
  },
  mounted() {
    this.getSelectPicker();
  }
};
</script>
